import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import edit from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import remove from '@iconify/icons-eva/trash-2-outline';
import launch from '@iconify/icons-eva/paper-plane-outline';
import view from '@iconify/icons-eva/eye-outline';
import viewResults from '@iconify/icons-eva/clipboard-outline';
import archive from '@iconify/icons-eva/archive-outline';
import PropTypes from 'prop-types';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// Data
// data
import { updateBriefStatus } from '../../../redux/slices/briefSlice';
import { useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

BriefMoreMenu.propTypes = {
  status: PropTypes.string,
  briefId: PropTypes.string
};

export default function BriefMoreMenu({ status, briefId }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const handleLaunch = () => {
    dispatch(updateBriefStatus({ briefId, status: 'in progress' }));
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {status === 'draft' && [
          <MenuItem
            key="edit"
            component={RouterLink}
            to={`/dashboard/brief/edit/${briefId}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Icon icon={edit} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem onClick={handleLaunch} key="launch" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={launch} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Launch" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="delete" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={remove} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ]}

        {status === 'in progress' && [
          <MenuItem key="view" component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={view} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="progress" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={viewResults} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View progress" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="delete" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={remove} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ]}

        {status === 'finished' && [
          <MenuItem key="view" component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={view} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="results" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={viewResults} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View results" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="archive" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={archive} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Archive" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>,

          <MenuItem key="delete" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={remove} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ]}
      </Menu>
    </>
  );
}
