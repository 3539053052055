//

import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  CardActionArea,
  Typography
} from '@mui/material';

import { useState } from 'react';
import { Icon } from '@iconify/react';
import addIcon from '@iconify/icons-eva/file-add-outline';
import { useTheme } from '@mui/material/styles';
import BriefOptionEditor from './BriefOptionEditor';

import { toggleStepsVisibility } from '../../../redux/slices/briefSlice';
import { useSelector, useDispatch } from '../../../redux/store';

export default function BriefOptions() {
  const { briefOptions } = useSelector((state) => state.brief);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const handleCreate = () => {
    dispatch(toggleStepsVisibility(false));
    setEditing(true);
  };
  const handleStopEditing = () => {
    dispatch(toggleStepsVisibility(true));
    setSelectedOption(null);
    setEditing(false);
  };
  const handleDetailsClick = (option) => () => {
    dispatch(toggleStepsVisibility(false));
    setSelectedOption(option);
    setEditing(true);
  };

  return (
    <>
      <Box
        sx={{
          mb: 5
        }}
      >
        <Typography>
          Great! Now that we have the background about what you are trying to achieve lets show the
          different options that fit the brief. At a minimum you would need to have 3 different
          options but you can upload up to 12.
        </Typography>
      </Box>
      {editing ? (
        <BriefOptionEditor onFinishedEditing={handleStopEditing} option={selectedOption} />
      ) : (
        <Box>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Card style={{ height: '100%' }}>
                <CardActionArea onClick={handleCreate} sx={{ height: '100%', textAlign: 'center' }}>
                  <CardContent>
                    <Icon
                      icon={addIcon}
                      color={theme.palette.primary.light}
                      width={70}
                      height={70}
                    />
                    <Typography variant="h6">Add a new creative option</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {/* Loop through the existing options and render */}
            {briefOptions.map((option, index) => (
              <Grid key={index} item md={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      option.files.length > 0
                        ? option.files[0]
                        : 'https://dummyimage.com/600x400/bcd6f4/0011ff&text=+'
                    }
                    alt="Fill murray"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {option.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {option.description.slice(0, 200)}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleDetailsClick(option)} size="small">
                      Edit
                    </Button>
                    <Button variant="contained" onClick={handleDetailsClick(option)} size="small">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
