//
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Box, TextField, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { setEndRound1, setEndRound2, setOptionsToProgress } from '../../../redux/slices/briefSlice';
import { useDispatch, useSelector } from '../../../redux/store';

export default function BriefLaunch() {
  const { briefTimings, briefOptions } = useSelector((state) => state.brief);
  const dispatch = useDispatch();

  const handleChangeRound1 = (newValue) => {
    dispatch(setEndRound1(newValue));
  };

  const handleChangeRound2 = (newValue) => {
    dispatch(setEndRound2(newValue));
  };

  const handleNumberOfBriefsRd2 = (event, numberOfBriefs) => {
    dispatch(setOptionsToProgress(numberOfBriefs));
  };

  return (
    <>
      <Box
        sx={{
          mb: 5
        }}
      >
        <Typography>
          Almost there! Last thing we need to do is to set when each round of assessment will start
          and end and then we can launch our brief
        </Typography>
      </Box>

      <Box>
        <Box sx={{ mb: 4 }}>
          <Typography sx={{ mb: 1 }} variant="body1">
            When do you want round 1 to finish?
          </Typography>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              value={briefTimings.endRound1}
              onChange={handleChangeRound1}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        {briefOptions.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ mb: 2 }} variant="body1">
              How many options do you want to go to round 2?
            </Typography>
            <ToggleButtonGroup
              color="primary"
              onChange={handleNumberOfBriefsRd2}
              exclusive
              variant="outlined"
              aria-label="outlined primary button group"
              value={briefTimings.optionsToProgress}
            >
              {briefOptions.map((option, index) => (
                <ToggleButton key={index} value={index + 1}>
                  {index + 1}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}

        <Box sx={{ mb: 4 }}>
          <Typography sx={{ mb: 1 }} variant="body1">
            When do you want round 2 to finish?
          </Typography>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              value={briefTimings.endRound2}
              onChange={handleChangeRound2}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </>
  );
}
