import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

BasicCarousel.propTypes = {
  items: PropTypes.array
};

function CarouselItem({ item }) {
  const { image, title } = item;

  return (
    <Box
      component="img"
      alt={title}
      src={image}
      sx={{ width: '100%', height: 480, objectFit: 'cover' }}
    />
  );
}

export default function BasicCarousel({ items }) {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next)
  };

  return (
    <Card>
      <Slider ref={carouselRef} {...settings}>
        {items.map((item) => (
          <CarouselItem key={item.title} item={item} />
        ))}
      </Slider>
    </Card>
  );
}
