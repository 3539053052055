//
import {
  Box,
  TextField,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  SvgIcon
} from '@mui/material';

import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/trash-2-fill';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { removeCriteria, updateCriteria } from '../../../redux/slices/briefSlice';
import { useDispatch } from '../../../redux/store';

BriefCriteria.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  importance: PropTypes.string,
  removable: PropTypes.bool
};

export default function BriefCriteria({ name, id, description, importance, removable }) {
  const dispatch = useDispatch();
  const [hovered, setHoveredState] = useState(false);

  const handleRemoveCriteria = (id) => dispatch(removeCriteria(id));
  const handleNameChange = (e) => {
    dispatch(
      updateCriteria({
        id,
        description,
        importance,
        name: e.target.value
      })
    );
  };

  const handleDescriptionChange = (e) => {
    dispatch(
      updateCriteria({
        id,
        description: e.target.value,
        importance,
        name
      })
    );
  };

  const handleImportanceChange = (e) => {
    dispatch(
      updateCriteria({
        id,
        description,
        importance: e.target.value,
        name
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          mb: 2
        }}
        onMouseEnter={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
      >
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Short name"
              variant="outlined"
              onChange={handleNameChange}
              value={name}
            />
          </Grid>
          <Grid item md={5}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Description"
              variant="outlined"
              onChange={handleDescriptionChange}
              value={description}
            />
          </Grid>

          <Grid item md={3}>
            <ToggleButtonGroup
              fullWidth
              color="primary"
              onChange={handleImportanceChange}
              exclusive
              variant="outlined"
              aria-label="outlined primary button group"
              value={importance}
            >
              <ToggleButton value="essential">Essential</ToggleButton>
              <ToggleButton value="secondary">Secondary</ToggleButton>
              <ToggleButton value="optional">Optional</ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item md={1}>
            {removable && hovered && (
              <IconButton onClick={() => handleRemoveCriteria(id)} aria-label="delete">
                <SvgIcon color="action">
                  <Icon icon={searchFill} width={24} height={24} />
                </SvgIcon>
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
