// material
import { styled } from '@mui/material/styles';
import { Grid, Card, Container, CardHeader, CardContent } from '@mui/material';
// routes
// components
import { BasicCarousel } from '../components/carousel/index';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(15)
}));

export default function Carousel() {
  return (
    <RootStyle title="Components: Carousel | Minimal-UI">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardHeader title="Carousel Basic 1" />
              <CardContent>
                <BasicCarousel
                  items={[
                    { title: 'test', image: 'http://www.fillmurray.com/g/200/300' },
                    { title: 'test', image: 'http://www.fillmurray.com/g/200/300' }
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
