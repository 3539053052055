import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import About from './pages/About';
import Brief from './pages/Brief';
import CreateBrief from './pages/CreateBrief';
import NotFound from './pages/Page404';
import Carousel from './pages/Carousel';

import { useAuth } from './contexts/SupabaseContext';

// ----------------------------------------------------------------------

export default function Router() {
  const auth = useAuth();

  return useRoutes([
    {
      path: '/dashboard',
      element: auth.user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'about', element: <About /> },
        { path: 'brief', element: <Brief /> },
        { path: 'brief/create/', element: <CreateBrief /> },
        { path: 'brief/edit/:id', element: <CreateBrief /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'carousel', element: <Carousel /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
