//
import { Typography, Grid, Button } from '@mui/material';

import { PropTypes } from 'prop-types';
import { UserCard } from '../user/index';
import { removeFromCohort } from '../../../redux/slices/briefSlice';
import { useDispatch } from '../../../redux/store';

CohortList.propTypes = {
  cohort: PropTypes.array,
  onAmendCohort: PropTypes.func
};

export default function CohortList({ cohort, onAmendCohort }) {
  const dispatch = useDispatch();
  const handleRemoveFromCohort = (id) => {
    dispatch(removeFromCohort(id));
    if (cohort.length <= 1) {
      onAmendCohort();
    }
  };

  return (
    <>
      <Typography variant="h6">Review your cohort</Typography>
      <Typography mb={2}>
        If you need to make any adjustments make them and then proceed to launching your brief
      </Typography>
      <Button onClick={onAmendCohort}>Add more greenlighters</Button>
      <Grid container spacing={3}>
        {cohort.map((greenLighter, index) => (
          <Grid key={index} item md={3}>
            <UserCard onRemoveFromCohort={handleRemoveFromCohort} user={greenLighter} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
