import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../../utils/supabaseClient';

const initialState = () => ({
  isLoading: false,
  error: false,
  status: 'draft',
  id: uuidv4(),
  name: '',
  description: '',
  nextStepAvailable: true,
  criterion: [
    {
      id: uuidv4(),
      name: '',
      description: '',
      importance: 'essential'
    },
    {
      id: uuidv4(),
      name: '',
      description: '',
      importance: 'essential'
    },
    {
      id: uuidv4(),
      name: '',
      description: '',
      importance: 'essential'
    }
  ],
  briefOptions: [],
  greenlighterSelection: {
    greenlighterType: '',
    greenlighterExpertise: [],
    numberOfGreenlighters: null,
    greenlighterMinAge: null,
    greenlighterMaxAge: null,
    greenlighterGender: null,
    selectedGreenlighters: []
  },
  briefTimings: {
    startRound1: new Date(),
    endRound1: new Date(),
    endRound2: new Date(),
    optionsToProgress: 3
  }
});

// Async methods
export const fetchBriefByID = createAsyncThunk('briefs/fetchByIdStatus', async (briefId) => {
  const response = await supabase
    .from('brief')
    .select('*,criterion(*),options(*),greenlighter(*)')
    .eq('id', briefId);
  return response.data;
});

export const uploadCreative = createAsyncThunk('briefs/uploadAsset', async (fileObj) => {
  const id = fileObj.id ? fileObj.id : uuidv4();
  const { file } = fileObj;
  // Read the file contents
  // Upload
  const { data, error } = await supabase.storage
    .from('briefassets')
    .upload(`uploads/${id}/${file.path}`, file, {
      cacheControl: '3600',
      upsert: true
    });
  return `${process.env.REACT_APP_UPLOADS_BASE_URL}${data.Key}`;
});

export const updateBriefStatus = createAsyncThunk('briefs/launch', async (payload) => {
  console.log(payload.status);
  const response = await supabase
    .from('brief')
    .update({ status: payload.status })
    .eq('id', payload.briefId);
  return response.data;
});

export const updateBrief = createAsyncThunk('briefs/updateStatus', async (state) => {
  const { id, name, description, status } = state.brief;
  // Will need to refactor to incorporate auth
  // Brief table
  // Validation
  // Error handling
  const briefUpdate = await supabase.from('brief').upsert({
    id,
    name,
    description,
    status,
    end_round1: state.brief.briefTimings.endRound1,
    end_round2: state.brief.briefTimings.endRound2,
    number_of_options: state.brief.briefTimings.optionsToProgress
  });

  // Criterion table
  // Validation
  // Error handling
  const dbCriterion = state.brief.criterion.map((criteria) => ({
    ...criteria,
    brief_id: id
  }));
  const criterionUpdate = await supabase.from('criterion').upsert(dbCriterion);

  // Options table
  // Validation
  // Error handling
  const dbOptions = state.brief.briefOptions.map((option) => ({
    id: option.id,
    name: option.name,
    author: option.author,
    description: option.description,
    brief_id: id,
    files: option.files
  }));
  const optionsUpdate = await supabase.from('options').upsert(dbOptions);

  // Brief Greenlighters table
  // Validation
  // Error handling
  const dbGreenlighterSelections = state.brief.greenlighterSelection.selectedGreenlighters.map(
    (greenlighter) => ({
      brief_id: id,
      greenlighter_id: greenlighter.id
    })
  );

  // Duplication of records because there is no id ref [Needs fix]
  const selectedGreenlighterUpdate = await supabase
    .from('brief_greenlighters')
    .upsert(dbGreenlighterSelections);
  return selectedGreenlighterUpdate.data;
});

// Actual slice
export const briefSlice = createSlice({
  name: 'brief',
  initialState: initialState(),
  reducers: {
    createId: (state, action) => {
      state.id = action.payload;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    addCriteria: (state) => {
      const criteria = {
        id: uuidv4(),
        name: '',
        description: '',
        importance: 'essential'
      };
      state.criterion.push(criteria);
    },
    removeCriteria: (state, action) => {
      state.criterion = state.criterion.filter((criteria) => criteria.id !== action.payload);
    },
    updateCriteria: (state, action) => {
      // This is gross will need to fix.
      const criteria = state.criterion.find((criteria) => criteria.id === action.payload.id);
      criteria.name = action.payload.name;
      criteria.description = action.payload.description;
      criteria.importance = action.payload.importance;
    },
    addOption: (state, action) => {
      const { name, author, description, files, uploadedFiles } = action.payload;
      const id = uuidv4();
      state.briefOptions.push({
        id,
        name,
        author,
        description,
        files,
        uploadedFiles
      });
    },
    addToCohort: (state, action) => {
      // Will need to refactor
      state.greenlighterSelection.selectedGreenlighters = action.payload;
    },
    removeFromCohort: (state, action) => {
      state.greenlighterSelection.selectedGreenlighters =
        state.greenlighterSelection.selectedGreenlighters.filter(
          (greenlighter) => greenlighter.id !== action.payload
        );
    },
    setStartDate: (state, action) => {
      state.briefTimings.startRound1 = action.payload;
    },
    setEndRound1: (state, action) => {
      state.briefTimings.endRound1 = action.payload;
    },
    setEndRound2: (state, action) => {
      state.briefTimings.endRound2 = action.payload;
    },
    setOptionsToProgress: (state, action) => {
      state.briefTimings.optionsToProgress = action.payload;
    },
    reset: (state) => initialState(),
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    toggleStepsVisibility: (state, action) => {
      state.nextStepAvailable = action.payload;
    }
  },
  extraReducers: {
    [fetchBriefByID.pending.type]: (state) => {
      state.isLoading = true;
    },
    // Update the state based on selected
    [fetchBriefByID.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      const { id, name, description, criterion, options, greenlighter } = action.payload[0];
      state.id = id;
      state.name = name;
      state.description = description;
      state.criterion = criterion.map((criteria) => ({
        id: criteria.id,
        name: criteria.name,
        description: criteria.description,
        importance: criteria.importance
      }));
      state.greenlighterSelection.selectedGreenlighters = greenlighter;
      state.briefOptions = options;
    },
    [uploadCreative.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [uploadCreative.fulfilled.type]: (state, action) => {
      state.isLoading = false;
    },
    [uploadCreative.rejected.type]: (state, action) => {
      console.log(action);
    },
    [updateBrief.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [updateBrief.fulfilled.type]: (state, action) => {
      state.isLoading = false;
    },
    [updateBrief.rejected.type]: (state, action) => {
      console.log(action);
    },
    [updateBriefStatus.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [updateBriefStatus.fulfilled.type]: (state, action) => {
      state.isLoading = false;
    },
    [updateBriefStatus.rejected.type]: (state, action) => {
      console.log(action);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createId,
  updateName,
  updateDescription,
  addCriteria,
  removeCriteria,
  updateCriteria,
  addOption,
  addToCohort,
  removeFromCohort,
  setStartDate,
  setEndRound1,
  setEndRound2,
  setOptionsToProgress,
  reset,
  updateStatus,
  toggleStepsVisibility
} = briefSlice.actions;

export default briefSlice.reducer;
