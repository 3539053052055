//
import { Box, Button, Stack, TextField, Typography, Paper } from '@mui/material';

import BriefCriteria from './BriefCriteria';

import {
  updateName,
  updateDescription,
  addCriteria,
  removeCriteria
} from '../../../redux/slices/briefSlice';
import { useSelector, useDispatch } from '../../../redux/store';

export default function BriefBackground() {
  const dispatch = useDispatch();

  /* State */
  const { name, description, criterion } = useSelector((state) => state.brief);
  /* Handlers */
  const handleBriefNameChange = (event) => {
    dispatch(updateName(event.target.value));
  };

  const handleDescriptionChange = (event) => {
    dispatch(updateDescription(event.target.value));
  };
  const handleAddCriteria = () => dispatch(addCriteria());
  const handleRemoveCriteria = (id) => dispatch(removeCriteria(id));

  return (
    <>
      <Box
        sx={{
          mb: 5
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">Brief background</Typography>
          <Typography>
            Setting up the brief in the right way will make sure we have success. Set the scene of
            what you are trying to achieve below.
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          mb: 5
        }}
      >
        <Stack direction="column" spacing={2}>
          <TextField
            id="outlined-basic"
            label="Name of the brief"
            variant="outlined"
            value={name}
            onChange={handleBriefNameChange}
          />
          <TextField
            multiline
            rows={6}
            id="outlined-basic"
            label="What is this brief about?"
            variant="outlined"
            value={description}
            onChange={handleDescriptionChange}
          />
        </Stack>
      </Box>

      <Box
        sx={{
          mb: 5
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1
            }
          }}
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">Scoring setup</Typography>
            <Typography>
              Now we need to look at how you would want people to score each of the presented
              options. This will be displayed in a spider graph to see how each option stacks up to
              the criteria at a glance
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <Box>
        {criterion.map((criteria) => (
          <BriefCriteria
            key={criteria.id}
            removable={criterion.length > 3}
            id={criteria.id}
            name={criteria.name}
            description={criteria.description}
            importance={criteria.importance}
            onRemoveCriteria={handleRemoveCriteria}
          />
        ))}

        {criterion.length < 5 && <Button onClick={() => handleAddCriteria()}>Add criteria</Button>}
      </Box>
    </>
  );
}
