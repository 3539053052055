//
import { Button, Typography, Box, Card, CardContent } from '@mui/material';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

BriefFinished.propTypes = {
  onReset: PropTypes.func,
  onLaunch: PropTypes.func
};

export default function BriefFinished({ onReset, onLaunch }) {
  const navigate = useNavigate();

  /* State */
  //   const { name, description, criterion } = useSelector((state) => state.brief);
  /* Handlers */
  const handleLaunchBrief = () => {
    onLaunch();
    navigate('/dashboard/brief');
  };

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    // backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      paddingTop: 40,
      paddingBottom: 50,
      margin: 'auto',
      marginTop: 50,
      justifyContent: 'center'
    }
  }));

  return (
    <>
      <RootStyle>
        <CardContent
          sx={{
            color: 'grey.800',
            p: { md: 0 },
            pl: { md: 5 }
          }}
        >
          <Box
            sx={{ width: '40%', margin: 'auto' }}
            component="img"
            src="/static/illustrations/illustration_launch.png"
          />

          <Typography gutterBottom variant="h4">
            Looks like we are ready to go!
          </Typography>

          <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto' }}>
            You have configured everything and we can launch or head back.
          </Typography>

          <Button onClick={handleLaunchBrief} variant="contained">
            Launch Now
          </Button>
        </CardContent>
      </RootStyle>
    </>
  );
}
