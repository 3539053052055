//
import PropTypes from 'prop-types';
import { Box, Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UploadMultiFile } from '../../upload/index';

import { addOption, uploadCreative } from '../../../redux/slices/briefSlice';
import { useSelector, useDispatch } from '../../../redux/store';

// Validation
import { validateOption } from '../../../utils/formValidator';

BriefOptionEditor.propTypes = {
  onFinishedEditing: PropTypes.func,
  option: PropTypes.object
};

export default function BriefOptionEditor({ onFinishedEditing, option }) {
  const dispatch = useDispatch();
  /* State */
  const [preview, setPreview] = useState(true);
  const [files, setFiles] = useState([]);
  const [name, setName] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');

  /* Handlers */
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFiles]
  );

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleFinishedEditing = () => onFinishedEditing();

  const handleSaveOption = async () => {
    console.log(option);
    const id = uuidv4();

    if (option) {
      // Edit the option placeholder
      onFinishedEditing();
    } else {
      // Upload the files
      const uploadedFiles = [];

      // Push all the queued files to supabase
      await Promise.all(
        files.map(async (file) => {
          const current = await dispatch(uploadCreative({ file, id }));
          uploadedFiles.push(current.payload);
        })
      );

      const option = {
        id,
        name,
        author,
        description,
        files: uploadedFiles
      };

      const validationStatus = await validateOption(option);

      if (validationStatus.valid) {
        // Add the option in
        dispatch(addOption(option));
        onFinishedEditing();
      } else {
        console.log('failed validation');
        console.log(validationStatus);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 5
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">Upload a new creative option</Typography>
          <TextField
            id="option-name"
            label="What is the name of this option?"
            variant="outlined"
            value={option ? option.name : name}
            onChange={handleNameChange}
          />
          <TextField
            id="option-author"
            label="Who created this option? (optional)"
            variant="outlined"
            value={option ? option.author : author}
            onChange={handleAuthorChange}
          />
          <TextField
            multiline
            rows={6}
            id="option-description"
            label="Describe this option"
            variant="outlined"
            value={option ? option.description : description}
            onChange={handleDescriptionChange}
          />

          {/* Display the images (pull this out into a new component) */}
          {option && (
            <Paper variant="outlined">
              <Grid container spacing={2}>
                {option.files.map((file, index) => (
                  <Grid key={index} item xs={2}>
                    <Paper square>
                      <img src={file} alt={file} />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}

          <Typography variant="label">Upload some supporting creative (Maximum 5GB)</Typography>
          <UploadMultiFile
            showPreview={preview}
            files={files}
            onDrop={handleDropMultiFile}
            onRemove={handleRemove}
            onRemoveAll={handleRemoveAll}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="outlined" onClick={handleFinishedEditing} color="error">
              Cancel
            </Button>
            <Button onClick={handleSaveOption} variant="contained">
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
