// material
import { Container, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { useState, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// components
import { useSnackbar } from 'notistack';
import Page from '../components/Page';
import LoadingScreen from '../components/LoadingScreen';
import {
  BriefBackground,
  BriefOptions,
  BriefSetup,
  BriefLaunch,
  BriefFinished
} from '../components/_dashboard/brief';

// data
import { reset, updateStatus, updateBrief, fetchBriefByID } from '../redux/slices/briefSlice';
import { useDispatch, useSelector } from '../redux/store';

// Validation
import { validateStep } from '../utils/formValidator';

export default function CreateBrief() {
  /* Steps context */
  const steps = ['Set the scene', 'Present your options', 'Find your cohort', 'Launch your brief'];
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state);
  const params = useParams();

  // Validation components
  const { enqueueSnackbar } = useSnackbar();

  // Reset on reload of page
  // Need to implement an id check on the route and use this to fetch
  useEffect(() => {
    if (params.id) {
      dispatch(fetchBriefByID(params.id));
    } else {
      dispatch(reset());
    }
  }, [dispatch]);

  /* Step handling */
  const handleNext = async () => {
    // Perform step validation
    const validationStatus = await validateStep(activeStep + 1, currentState.brief);

    // persist and progress
    if (validationStatus.valid) {
      await dispatch(updateBrief(currentState));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // There are errors help the user
      enqueueSnackbar(
        // Pull this into a separate snackbar component
        <div>
          <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
            Oops!
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Looks like we missed something. Lets fix those errors and we should be good to go.
          </Typography>
        </div>,
        { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } }
      );
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch(reset());
    setActiveStep(0);
  };

  const handleLaunch = () => {
    // This needs to be an async thunk
    dispatch(updateStatus('in progress'));
  };

  const stepContent = () => {
    let content = 'not mapped';

    if (activeStep === 0) {
      content = <BriefBackground />;
    } else if (activeStep === 1) {
      content = <BriefOptions />;
    } else if (activeStep === 2) {
      content = <BriefSetup />;
    } else if (activeStep === 3) {
      content = <BriefLaunch />;
    }

    return content;
  };

  /* Render */
  return (
    <Page title="Create Brief | Open Creative">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Brief
          </Typography>
        </Stack>
        {currentState.brief.isLoading ? (
          <LoadingScreen />
        ) : (
          <Box sx={{ width: '100%', justifyContent: 'center' }}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <>
                {/* Finished */}
                <BriefFinished onLaunch={handleLaunch} onReset={handleReset} />
              </>
            ) : (
              <>
                {/* Content */}
                <Box
                  sx={{
                    mt: 5
                  }}
                >
                  {stepContent(activeStep)}
                </Box>

                {/* Controls */}
                {currentState.brief.nextStepAvailable && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button variant="contained" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Save and continue'}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}
