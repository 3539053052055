import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Avatar,
  Typography,
  Rating,
  CardActions,
  IconButton,
  SvgIcon
} from '@mui/material';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/trash-2-fill';
// utils
//
import SvgIconStyle from '../../SvgIconStyle';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: '70px',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

UserCard.propTypes = {
  user: PropTypes.object,
  onRemoveFromCohort: PropTypes.func
};

export default function UserCard({ user, onRemoveFromCohort, ...other }) {
  const { firstName, lastName, expertise, avatar, rating } = user;
  const handleRemoveFromCohort = () => onRemoveFromCohort(user.id);
  const name = `${firstName} ${lastName}`;

  return (
    <Card {...other}>
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            position: 'absolute',
            transform: 'translateY(-50%)'
          }}
        />
      </CardMediaStyle>

      <Typography variant="subtitle1" align="center" sx={{ mt: 6 }}>
        {name}
      </Typography>
      <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
        {expertise.join(', ')}
      </Typography>

      <Grid container sx={{ py: 1, textAlign: 'center' }}>
        <Grid item xs={12}>
          <Rating name="read-only" value={rating} readOnly />
        </Grid>
      </Grid>
      <CardActions disableSpacing>
        <IconButton onClick={handleRemoveFromCohort} aria-label="remove from cohort">
          <SvgIcon color="action">
            <Icon icon={searchFill} width={24} height={24} />
          </SvgIcon>
        </IconButton>
      </CardActions>
    </Card>
  );
}
