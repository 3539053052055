//
import { useState } from 'react';
import {
  Button,
  Box,
  Stack,
  Chip,
  Typography,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

import CohortList from './CohortList';
import BriefUserSearchResults from './BriefUserSearchResults';
import { addToCohort, toggleStepsVisibility } from '../../../redux/slices/briefSlice';
import { useSelector, useDispatch } from '../../../redux/store';
import { supabase } from '../../../utils/supabaseClient';

// ----------------------------------------------------------------------

export default function BriefSetup() {
  const { greenlighterSelection } = useSelector((state) => state.brief);
  const dispatch = useDispatch();

  const [selectionCriteria, setSelectionCriteria] = useState('name');
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [numberOfGreenlighters, setNumberOfGreenlighters] = useState(3);
  const [hasSearchResults, setSearchResultsStatus] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [cohortSelected, setCohortStatus] = useState(false);
  const expertises = ['finance', 'marketing', 'operations', 'sales', 'strategy', 'technology'];

  const handleSelectionCriteria = (event, newSelectionCriteria) => {
    setSearchResultsStatus(false);
    setSelectionCriteria(newSelectionCriteria);
    setSelectedExpertise([]);
  };

  const handleNumberOfGreenlighters = (event, numberOfGreenlighters) => {
    setNumberOfGreenlighters(numberOfGreenlighters);
  };

  const isSelectedExpertise = (expertise) => selectedExpertise.includes(expertise);

  const handleExpertise = (selected) => {
    if (selectedExpertise.includes(selected)) {
      setSelectedExpertise(selectedExpertise.filter((item) => item !== selected));
    } else {
      setSelectedExpertise([...selectedExpertise, selected]);
    }
  };

  const handleSearch = async () => {
    // Perform the search based on search type and get the results
    let results = [];
    switch (selectionCriteria) {
      case 'name':
        results = await searchByTop();
        break;
      case 'expertise':
        results = await searchByExpertise(selectedExpertise);
        break;
      case 'random':
        results = await searchByRandom(numberOfGreenlighters);
        break;
      default:
        results = await searchByTop();
        break;
    }
    setSearchResults(results);
    // Perform search (need to remove the bool and add search logic)
    setSearchResultsStatus(true);
    // Clear the cohort view
    setCohortStatus(false);
    // Auto add on random
    if (selectionCriteria === 'random')
      dispatch(addToCohort(searchResults.slice(0, numberOfGreenlighters)));
    // Remove steps so can review the selections
    else dispatch(toggleStepsVisibility(false));
    return results;
  };

  const searchByTop = async () => {
    const { data, error } = await supabase.from('greenlighter').select('*');
    if (error) {
      // TODO: handle the error
    }
    return data;
  };

  const searchByExpertise = async (criteria) => {
    const { data, error } = await supabase
      .from('greenlighter')
      .select('*')
      .contains('expertise', criteria);
    if (error) {
      // TODO: handle the error
    }
    return data;
  };

  const searchByRandom = async (number) => {
    const { data, error } = await supabase.from('greenlighter').select('*').limit(number);
    if (error) {
      // TODO: handle the error
    }
    return data;
  };

  const handleAddedToCohort = () => {
    // Clear search results
    setSearchResultsStatus(false);
    dispatch(toggleStepsVisibility(true));
    // Display cohort list
    setCohortStatus(true);
  };

  const handleAmendCohort = () => setCohortStatus(false);

  return (
    <>
      <Box
        sx={{
          mb: 5
        }}
      >
        <Typography>
          Now that we have the brief ready lets find some greenlighters to start assessing the
          options.
        </Typography>
      </Box>

      <Box>
        {/* Show the cohort selectors */}
        {!cohortSelected && (
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ mb: 1 }} variant="body1">
              How would you like to select your audience?
            </Typography>
            <Stack gap={2} direction="row">
              <ToggleButtonGroup
                color="primary"
                onChange={handleSelectionCriteria}
                exclusive
                variant="outlined"
                aria-label="outlined primary button group"
                value={selectionCriteria}
              >
                <ToggleButton value="name">Browse top</ToggleButton>
                <ToggleButton value="expertise">By Expertise</ToggleButton>
                <ToggleButton value="random">Random</ToggleButton>
              </ToggleButtonGroup>
              <Button onClick={handleSearch} variant="contained">
                Search
              </Button>
            </Stack>

            {selectionCriteria === 'expertise' && (
              <Box marginTop={3}>
                <Typography>Which expertises do you want to target?</Typography>

                <Stack marginTop={1} direction="row" spacing={1}>
                  {expertises.map((expertise) => (
                    <Chip
                      key={expertise}
                      label={expertise}
                      color={isSelectedExpertise(expertise) ? 'primary' : 'default'}
                      component="a"
                      onClick={() => handleExpertise(expertise)}
                      clickable
                    />
                  ))}
                </Stack>
              </Box>
            )}
            {selectionCriteria === 'random' && (
              <Box marginTop={3}>
                <Typography sx={{ mb: 2 }} variant="body1">
                  How many greenlighters do you want in your audience?
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  onChange={handleNumberOfGreenlighters}
                  exclusive
                  variant="outlined"
                  aria-label="outlined primary button group"
                  value={numberOfGreenlighters}
                >
                  <ToggleButton value={3}>3</ToggleButton>
                  <ToggleButton value={4}>4</ToggleButton>
                  <ToggleButton value={5}>5</ToggleButton>
                  <ToggleButton value={6}>6</ToggleButton>
                  <ToggleButton value={7}>7</ToggleButton>
                  <ToggleButton value={8}>8</ToggleButton>
                  <ToggleButton value={9}>9</ToggleButton>
                  <ToggleButton value={10}>10</ToggleButton>
                  <ToggleButton value={11}>11</ToggleButton>
                  <ToggleButton value={12}>12</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          </Box>
        )}

        {/* Show the greenlighter search results */}
        {hasSearchResults && (
          <BriefUserSearchResults onAddedToCohort={handleAddedToCohort} results={searchResults} />
        )}

        {/* Show the cohort */}
        {cohortSelected && (
          <CohortList
            onAmendCohort={handleAmendCohort}
            cohort={greenlighterSelection.selectedGreenlighters}
          />
        )}
      </Box>
    </>
  );
}
