// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';

export default function About() {
  return (
    <Page title="About | Open Creative">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            About
          </Typography>
        </Stack>
      </Container>
    </Page>
  );
}
