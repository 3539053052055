import * as Yup from 'yup';

// Step validator
export async function validateStep(stepNumber, brief) {
  const status = {
    valid: true,
    errors: []
  };
  try {
    switch (stepNumber) {
      case 1:
        await backgroundSchema.validate(brief, { abortEarly: false });
        break;
      case 2:
        await optionsSchema.validate(brief, { abortEarly: false });
        break;
      case 3:
        await greenlighterSchema.validate(brief.greenlighterSelection, { abortEarly: false });
        break;
      case 4:
        // timingsSchema.validate(brief, { abortEarly: false });
        break;
      default:
        // briefSchema.validate(brief, { abortEarly: false });
        break;
    }
  } catch (err) {
    status.valid = false;
    err.inner.forEach((e) => {
      status.errors.push(e.message);
    });
  }
  console.log(status);
  return status;
}

// Specific for options
export async function validateOption(option) {
  const status = {
    valid: true,
    errors: []
  };
  try {
    await optionSchema.validate(option, { abortEarly: false });
  } catch (err) {
    status.valid = false;
    err.inner.forEach((e) => {
      status.errors.push(e.message);
    });
  }
  console.log(status);
  return status;
}

// Step 1 - Background
// Name - not empty
// Description - not empty
// Criteria - Minimum of 3 total
// Criteria Name - Not empty
// Criteria Description - Not empty
export const backgroundSchema = Yup.object({
  name: Yup.string().required('Need a name for this brief'),
  description: Yup.string().required(),
  criterion: Yup.array()
    .min(3)
    .of(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
        importance: Yup.string().required()
      })
    )
});

// Step 2 - Options summary
// At least 3 options
export const optionsSchema = Yup.object({
  briefOptions: Yup.array()
    .min(3)
    .of(
      Yup.object({
        id: Yup.string().required(),
        author: Yup.string(),
        name: Yup.string().required(),
        description: Yup.string().required(),
        files: Yup.array().of(Yup.string()).min(1)
      })
    )
});

// Step 2a - Option creation
// Name - Not empty
// Description - Not empty
// Files - At least 1 option uploaded (img, pdf, video)
export const optionSchema = Yup.object({
  id: Yup.string().required(),
  author: Yup.string(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  files: Yup.array().of(Yup.string()).min(1)
});

// Step 3 - Greenlighter selection
// Greenlighters - At least 3 greenlighters selected
export const greenlighterSchema = Yup.object({
  selectedGreenlighters: Yup.array().min(3)
});

// Step 4 - Brief timings
// Start time - Cannot be in the past
// Number of options - Cannot be empty
// End time - Cannot be before the start time
